import { Component,ElementRef,OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { RoutedataService } from '../landing/control/routedata.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  bgColor = "bg-warning";
  Title = "Sri Shirdi Sai Biksha Kendra (R) <br/> ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರ (ರಿ)";
  pageTitle='';
  docContent:""="";
  tabContents:Array<object[]>=[];
  @ViewChild('TabHolderdiv', { static: false }) TabHolderdiv!: ElementRef;

  pageTitle_Kn='';
  docContent_Kn='';
  Mobtitle = "SSBK";
  titlColor = "text-danger";
  IsBaba: boolean = true;
  CurrentContents:object={};
  currentYear=new Date().getFullYear();
  loginForm!: FormGroup;
  loading = false;
     submitted = false;
     returnUrl: string="" ;
     error = '';
 constructor(private authS: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,
         private router: Router,private rd: RoutedataService){
     
 }
     
       ngOnInit() {
      
 this.loginForm = this.formBuilder.group({
             username: ['', [Validators.required, Validators.email]],
             password: ['', Validators.required]
         });
         this.rd.PLUpdate.subscribe(datas => { 
          if (datas) { 
            if (datas["data"]) {
              
              if (datas["data"]["title"]) {
                this.Title = datas["data"]["title"];
              }
              if (datas["data"]["mobtitle"]) {
                this.Mobtitle = datas["data"]["mobtitle"];
              }
              if (datas["data"]["titlecolor"]) {
                this.titlColor = datas["data"]["titlecolor"];
              }
              
              if (datas["data"]["ISBaba"]) {
    
                this.IsBaba = (datas["data"]["ISBaba"] == "1") ? true : false;
    
              }
    
            }
    
          }
        });
          
   }
     
     get f() { return this.loginForm.controls; }
     
    onSubmit() {
   let self=this;
    this.submitted = true;
  if (this.loginForm.invalid) {
   //this.alertService.error("User name and Password required",AlertPosition.CenterTop);
             return;
         }
            this.loading = true;
              setTimeout(()=>{
                  
   this.authS.login(this.f["username"].value, this.f["password"].value).pipe(first())
             .subscribe(
               (                 data: any) => {
                   if(!data){
                       this.loading = false;
                        this.submitted = false;
                    // this.alertService.error("username or password did not match *",AlertPosition.CenterTop);
                
                      }else{
                          if(this.route.snapshot.queryParams["returnUrl"]){
                             this.router.navigate([this.route.snapshot.queryParams["returnUrl"]]);
                             }else{
                                this.router.navigate(['/episodes']); 
                             }
 
                      }
                
                 },
                 () => {
                    
                     this.loading = false;
                 });
    },3000);
   }

}
