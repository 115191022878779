import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AppConfigService } from '../services/app-config.service';
import {AdminUser } from '../models/adminuser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private UserSubject: BehaviorSubject<any>;
 public User: Observable<any>;
    apiurl=AppConfigService.settings.apiServer.apiurl;
  constructor(private http: HttpClient) { 
  this.UserSubject = new BehaviorSubject<any>(localStorage.getItem('admin'));
	        this.User = this.UserSubject.asObservable();
  }
    
      public get AdminUserValue():any {
	        return this.UserSubject.value;
	    }
    
    login(username: string, password: string) {
	       var formData: any = new FormData();
         formData.append("Username", username);
         formData.append("Password", password);
        var postpara={
           Username: username,
            Password:password
        }
       return this.http.post<any>(this.apiurl+'userauth/login', postpara)
            .pipe(map(user => {
             
                if (user && user.token) {
               
                    localStorage.setItem('admin', JSON.stringify(user));
                    this.UserSubject.next(user);
                     return user;
                }else{
                    
                }

               return false;
            }));  
	    }
    
    logout() {
	        
	        localStorage.removeItem('admin');
	        this.UserSubject.next(null);
	        
	    }
}
