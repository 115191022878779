import { Injectable } from '@angular/core';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
     constructor(
        private router: Router,
        private authS: AuthService
    ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  const currentUser = this.authS.AdminUserValue;
 
       
    if (currentUser) {
              return true;
           }

         return  this.router.createUrlTree(['login'], { queryParams: { returnUrl: state.url }})
  }
  
}
