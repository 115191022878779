<div class=" bg-warning vh-100">
    <div class="app-body d-flex align-items-stretch" style="min-height: calc(100vh - 30px); ">
        <main class="main  flex-fill">
            <div class="container-fluid h-100">
                <div class="row h-100 align-items-md-center justify-content-center">
                    <div class="col-md-5 d-flex flex-column align-items-center justify-content-center">
                        <div class="mb-3">
                            <span><img src="assets/img/sssbk.png" style="
                    -webkit-filter: drop-shadow(0px 1px 1px #222);
                    filter: drop-shadow(0px 1px 1px #222);
                  " />&nbsp;</span>
                            <img src="assets/img/gls-sm.png" />&nbsp;
                        </div>
                        <div>
                            <span class="display-6 m-0 px-1 {{ titlColor }}"><span 
                                    [innerHTML]="Title"></span></span>&nbsp;
                        </div>
                    </div>
                    <div class="col-md-4 ">
                        <div class="card-group ms-md-auto mx-auto" style="max-width: 380px;">
                            <div class="card border-0">
                                <div class="card-body">
                                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                        <h1>Login</h1>
                                        <p class="text-muted">Sign In to your account</p>
                                        <div class="mb-3 form-floating">
                                            <input type="text" class="form-control" formControlName="username"
                                                [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
                                                placeholder="Email" autocomplete="username" required>
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                        <div class="mb-3 form-floating">
                                            <input type="password" class="form-control" formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                                placeholder="Password" autocomplete="current-password" required>
                                            <label for="floatingInput">Password</label>
                                        </div>

                                        <div class="row">

                                            <div class="col-6 text-right d-none">
                                                <button type="button" class="btn btn-link px-0">Forgot
                                                    password?</button>
                                            </div>
                                            <div class="col">
                                                <div class="d-grid gap-1 col-12 mx-auto"> <button type="submit"
                                                        class="btn btn-warning px-4" data-style="expand-right"
                                                        [disabled]="loading"><span *ngIf="!loading">Login</span>
                                                        <div *ngIf="loading">
                                                            <div class="spinner-grow text-dark  " style="--bs-spinner-animation-speed:0.75s;--bs-spinner-width: 0.8rem;
                                                        --bs-spinner-height: 0.8rem;" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>&nbsp;
                                                            <div class="spinner-grow text-dark  " style="--bs-spinner-animation-speed:0.65s;--bs-spinner-width: 0.8rem;
                                                          --bs-spinner-height: 0.8rem;" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>&nbsp;
                                                            <div class="spinner-grow text-dark" style="--bs-spinner-animation-speed:0.55s;--bs-spinner-width: 0.8rem;
                                                          --bs-spinner-height: 0.8rem;" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </button></div>


                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div>
    <div class="container-fluid text-white">
        <div class="row">
            <div class="col-md-7 text-end">
                <p class="m-0">Copyright © {{currentYear}} All Rights Reserved</p>
            </div>
            <div class="col-md-5"></div>
        </div>
    </div>
</div>