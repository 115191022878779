import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthComponent } from './view/auth/auth.component';
import { AuthGuard } from './helper/auth.guard';

const routes: Routes = [{
  path: 'login',
  component: AuthComponent, data: {
          navbg: 'bg-warning',
          title: "Sri Shirdi Sai Biksha Kendra (R) <br/> ಶ್ರೀ ಶಿರಡಿ ಸಾಯಿ ಭಿಕ್ಷಾ ಕೇಂದ್ರ (ರಿ)",
          mobtitle: "SSBK",
          titlecolor: "text-danger",
          docTitle: "Dr Guruji Lakshmi Srinivas & Sri Shirdi Sai Biksha Kendra(SSBK) Agnihotra Registration",
          ISBaba: "1"
  }

},{
  path:'',
  canActivate: [AuthGuard],
  loadChildren:()=>import('./view/landing/landing.module').then(m=>m.LandingModule)
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
